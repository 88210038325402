import { onMount } from "solid-js";
import { isServer } from "solid-js/web";

export default function TriggerServerError() {
  if (isServer) {
    throw new Error("test server error");
  }

  return (
    <main class="text-center mx-auto text-gray-700 p-4">
      <h1 class="max-6-xs text-6xl text-sky-700 font-thin uppercase my-16">
        An error in the server triggered...
      </h1>
    </main>
  );
}
